
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
// import TableHeaderChange from "@/layout/header/partials/trainning_institute/TableHeaderChange.vue";
import Multiselect from '@vueform/multiselect';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import { VueCookieNext } from 'vue-cookie-next';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { ElNotification } from 'element-plus';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'student-summary-dynamicreport',
  components: {
    Datatable,
    Multiselect,
    // TableHeaderChange,
  },

  data() {
    return {
      apiUrl: '',
      batch: {
        entity_id: [] as any,
        tranche_id: [] as any,
        institute_id: '',
        course_id: '',
        poor: '',
        challenged: '',
        ethnic: '',
        gender: '',
      },
      employee: {},
      details: {},
      selected: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      instituteList: [],
        optionsTranche: [] as any,
      tranches_info: [] as any,
      courseInfoData: [] as any,
      TpartnerInfos: [] as any,
      TrancheInfos: [] as any,
      optionsTPartner: [] as any,
      courseList: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      count: {},
      selectedEntity: [],
      selectedTrance: [],
      selectedCourseType: [],
      showDetailsTrainee: false,
      selectedCourse: [],
      selectedDistrict: [],
      updatedArray: [] as any,
      tableData: [] as any,
      tableHeader: [
        {
          name: 'Tranche',
          key: 'tranche',
          sortable: true,
          width: '5px',
        },
        {
          name: 'Photo',
          key: 'photo',
          sortable: false,
        },
        {
          name: 'Entity',
          key: 'entity',
          sortable: true,
          selected: false,
        },
        {
          name: 'Institute Name',
          key: 'institute_name',
          sortable: true,
          selected: false,
        },
        {
          name: 'Registration Number',
          key: 'reg_number',
          sortable: true,
          selected: true,
        },
        {
          name: 'Trainee Name',
          key: 'name',
          sortable: true,
        },
        {
          name: 'NID',
          key: 'nid',
          sortable: true,
          selected: true,
        },
        {
          name: 'BCN',
          key: 'bcn',
          sortable: true,
          selected: true,
        },
        {
          name: 'Present Address',
          key: 'present_add',
          sortable: true,
          selected: true,
          width: '150px',
        },
        {
          name: 'Present City',
          key: 'present_city',
          sortable: true,
        },
        {
          name: 'Present Post Code',
          key: 'postCode',
          sortable: true,
        },
        {
          name: 'Present District',
          key: 'present_district',
          sortable: true,
        },
        {
          name: 'mobile',
          key: 'mobile',
          sortable: true,
        },
        {
          name: 'Gender',
          key: 'gender',
          selected: true,
          sortable: true,
        },
        {
          name: 'Batch Number',
          key: 'batch_number',
          selected: true,
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Active Status',
          key: 'active_status',
          sortable: true,
        },
      ] as any,
      loading: false,
      showTableData: false,
      showtable: true,
      componentKey: 0,
      componentTableKey: 0,
      load: false,
      lowerAge: 0 as any,
      upperAge: 0 as any,
    };
  },
  async created() {
    this.apiUrl = this.VUE_APP_API_URL;
   await this.getAssociation();
    await this.getTranche();
    // await this.getallCourse();
  },
    watch: {
    "batch.entity_id": {
      handler: "getTrainingInstitute", // Call the method when batch.entity_id changes
      immediate: false, // Set this to false if you want to trigger on initial mount
    },
  },
  methods: {
    async downloadPdf() {
        ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();
      formData.set('lowerAge', this.lowerAge);
      formData.set('upperAge', this.upperAge);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_id', this.batch.entity_id);
      formData.set('institute_id', this.batch.institute_id);
      formData.set('course_id', this.batch.course_id);
      await ApiService.post('trainee/age_range_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
        ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let formData = new FormData();

      formData.set('lowerAge', this.lowerAge);
      formData.set('upperAge', this.upperAge);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_id', this.batch.entity_id);
      formData.set('institute_id', this.batch.institute_id);
      formData.set('course_id', this.batch.course_id);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/trainee/age_range_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Student Age Range.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
   async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.TrancheInfos = response.data.data;
          this.TrancheInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAssociation() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.TpartnerInfos = response.data.data;
          this.TpartnerInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => { });
    },
   async getTrainingInstitute() {
      this.loading = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
        '?entity_id=' +
        this.batch.entity_id +
        '&institute_info_id=' +
        institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async courseInfo() {
      this.batch.course_id = '';
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&tranche=' +
          this.batch.tranche_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    async agewiseCount() {
      
        this.loading = true;
        await ApiService.get(
          'trainee/age-wise-report?tranche_id=' +
            this.batch.tranche_id +
            '&entity_id=' +
            this.batch.entity_id +
            '&institute_id=' +
            this.batch.institute_id +
            '&course_id=' +
            this.batch.course_id  +
          '&gender=' +
          this.batch.gender
        )
          .then((response) => {
            this.count = response.data.data;
            this.showTableData = true;
            this.showDetailsTrainee = false;
            //this.tableData = response.data.data;
            this.loading = false;
          })
          .then((response) => {
            console.log(response);
            this.loading = false;
          });  
    },

    getAgeRangeTrainee(lowerAge, upperAge) {
      this.lowerAge = lowerAge;
      this.upperAge = upperAge;
      this.loading = true;
      if (this.batch.tranche_id != '') {
         ApiService.get(
        'trainee/age-range-list?lowerAge=' +
          lowerAge +
          '&upperAge=' +
          upperAge +
          '&tranche_id=' +
          this.batch.tranche_id +
          '&entity_id=' +
          this.batch.entity_id +
          '&institute_id=' +
          this.batch.institute_id +
          '&course_id=' +
          this.batch.course_id +
           '&gender=' +
        this.batch.gender
      )
        .then((response) => {
          console.log(response);
          this.tableData = response.data.data;
          this.componentTableKey += 1;
          this.showDetailsTrainee = true;
          this.loading = false;
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
        });
      }
      else {
        Swal.fire({
          title: 'Warning!',
          text: 'Please Select Tranche!',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
        this.loading = false;
      }
    },
  },
  computed: {},
  setup() {},
});
